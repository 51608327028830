import { cn } from "~/utils/classnames";

import "./Typo.css";
import type { TypoProps } from "./Typo.types";

export const Typo = ({
  children,
  as = "p",
  fontSize = "base",
  fontWeight = "normal",
  fontColor = "grey-900",
  className,
  ...props
}: TypoProps) => {
  const Tag = as;

  return (
    <Tag
      className={cn(
        `Typo--fontSize-${fontSize}`,
        `Typo--fontWeight-${fontWeight}`,
        `Typo--fontColor-${fontColor}`,
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
